export default [
  {
    name: 'Form Editor',
    path: '/account-forms/:id/edit',
    component: () => import(/* webpackChunkName: "forms" */ '@/views/forms/FormEditorView.vue')
  },
  {
    name: 'Account Forms',
    path: '/account-forms/:id?',
    component: () => import(/* webpackChunkName: "forms" */ '@/views/forms/FormListView.vue')
  },
  {
    name: 'Account Packets',
    path: '/account-packets/:id?',
    component: () => import(/* webpackChunkName: "forms" */ '@/views/forms/PacketListView.vue')
  },
  {
    name: 'Form Submissions',
    path: '/form-submissions/:id?',
    component: () => import(/* webpackChunkName: "forms" */ '@/views/forms/SubmissionListView.vue')
  },
  {
    name: 'Submission Filler',
    path: '/submission/:privateUrl',
    component: () => import(/* webpackChunkName: "forms" */ '@/views/forms/SubmissionFillerView.vue')
  },
  {
    name: 'Single Form Filler',
    path: '/forms/:formId',
    component: () => import(/* webpackChunkName: "forms" */ '@/views/forms/SubmissionFillerView.vue')
  },
  {
    name: 'Packet Filler',
    path: '/packets/:packetId',
    component: () => import(/* webpackChunkName: "forms" */ '@/views/forms/SubmissionFillerView.vue')
  }
]