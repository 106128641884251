export default [
  {
    name: 'Faxes Inbox',
    path: '/fax-inbox/:id?',
    component: () => import(/* webpackChunkName: "faxing" */ '@/views/faxing/FaxInboxView.vue')
  },
  {
    name: 'Faxes Outbox',
    path: '/fax-outbox/:id?',
    component: () => import(/* webpackChunkName: "faxing" */ '@/views/faxing/FaxOutboxView.vue')
  }
]