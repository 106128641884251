export default [
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/core/RegisterView.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/core/LoginView.vue')
  },
  {
    path: '/apps',
    name: 'Apps',
    component: () => import(/* webpackChunkName: "core" */ '@/views/core/AppsView.vue')
  },
  {
    path: '/patients',
    name: 'Patients',
    component: () => import(/* webpackChunkName: "core" */ '@/views/core/PatientsView.vue')
  },
  {
    path: '/account-settings/:page?',
    name: 'Settings',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/core/settings/AccountSettingsView.vue')
  },
  {
    path: '/user-settings/:id?',
    name: 'User Settings',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/core/settings/UserSettingsView.vue')
  },
  {
    path: '/invite/:url',
    name: 'Accept Invite',
    component: () => import(/* webpackChunkName: "core" */ '@/views/core/AcceptInviteView.vue')
  }
]