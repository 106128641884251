import { postJson, postForm } from './common.js'

// Account

export async function logout() {
  await postJson('/core/user/logout');
}

export async function createAccount(name, masterEmail, masterPassword) {
  const resJson = await postJson('/core/account/create', { name, masterEmail, masterPassword });
  if (!resJson.account) throw Error('Malformed response from backend');
  return resJson.account;
}

export async function fetchAccountInfo() {
  const resJson = await postJson('/core/account/info');
  if (!resJson.account) throw Error('Malformed response from backend');
  return resJson.account;
}

export async function updateAccountSettings(payload) {
  const resJson = await postJson('/core/account/update-settings', payload);
  if (!resJson.account) throw Error('Malformed response from backend');
  return resJson.account;
}

export async function emrToPatient(emrIntegrationId) {
  const resJson = await postJson('/core/patient/emr-to-patient', { emrIntegrationId });
  return resJson;
}

// Users

export async function userCheckAuth() {
  await postJson('/core/user/check-auth');
}

export async function listAllUsers() {
  const resJson = await postJson('/core/user/list-all');
  if (!resJson.users) throw Error('Malformed response from backend');
  return resJson.users;
}

export async function updateUserSettings(payload) {
  const resJson = await postJson('/core/user/update-settings', payload);
  if (!resJson.user) throw Error('Malformed response from backend');
  return resJson.user;
}

export async function uploadUserProfilePicture(userId, file) {
  const formBody = new FormData();
  formBody.set('file', file);
  formBody.set('userId', userId);

  const resJson = await postForm('/core/user/upload-profile-picture', formBody);
  if (!resJson.key) throw Error('Malformed response from backend');
  return resJson.key;
}

export async function listInvitations() {
  const resJson = await postJson('/core/userinvitation/list');
  if (!resJson.invitations) throw Error('Malformed response from backend');
  return resJson.invitations;
}

export async function inviteUser(name, email, practiceRole) {
  const resJson = await postJson('/core/userinvitation/create', { name, email, practiceRole });
  if (!resJson.invitation) throw Error('Malformed response from backend');
  return resJson.invitation;
}

export async function revokeInvite(invitationId) {
  await postJson('/core/userinvitation/revoke', { invitationId });
}

export async function useOneTimeCode(oneTimeCode) {
  await postJson('/core/user/use-otc', { oneTimeCode });
}

// Patient

export async function createPatient({ firstName, middleName, lastName, dateOfBirth, emrIntegrationId, contactMethods }) {
  const resJson = await postJson('/core/patient/create', { firstName, middleName, lastName, dateOfBirth, emrIntegrationId, contactMethods });
  if (!resJson.patient) throw Error('Malformed response from backend');
  return resJson.patient;
}

export async function addContactMethod({ patientId, methodType, phoneNumber, emailAddress, description }) {
  const resJson = await postJson('/core/patient/add-contact-method', { patientId, methodType, phoneNumber, emailAddress, description });
  if (!resJson.patient) throw Error('Malformed response from backend');
  return resJson.patient;
}

export async function editContactMethod({ patientId, methodId, description }) {
  const resJson = await postJson('/core/patient/edit-contact-method', { patientId, methodId, description });
  if (!resJson.patient) throw Error('Malformed response from backend');
  return resJson.patient;
}

export async function removeContactMethod({ patientId, methodId }) {
  const resJson = await postJson('/core/patient/remove-contact-method', { patientId, methodId });
  if (!resJson.patient) throw Error('Malformed response from backend');
  return resJson.patient;
}

export async function optInContactMethod({ patientId, methodId, isOptedIn }) {
  const resJson = await postJson('/core/patient/opt-in-contact-method', { patientId, methodId, isOptedIn });
  if (!resJson.patient) throw Error('Malformed response from backend');
  return resJson.patient;
}

export async function updatePatient(patientId, fieldsToUpdate) {
  const resJson = await postJson('/core/patient/update', { patientId, ...fieldsToUpdate });
  if (!resJson.patient) throw Error('Malformed response from backend');
  return resJson.patient;
}

export async function searchPatients(query, afterPatientId) {
  const resJson = await postJson('/core/patient/search', { query, afterPatientId });
  if (!resJson.patients) throw Error('Malformed response from backend');
  return resJson;
}

export async function fetchPatientInfo(patientId) {
  const resJson = await postJson('/core/patient/info', { patientId });
  if (!resJson.patient) throw Error('Malformed response from backend');
  return resJson.patient;
}

export async function uploadPatientProfilePicture(patientId, file) {
  const formBody = new FormData();
  formBody.set('file', file);
  formBody.set('patientId', patientId);

  const resJson = await postForm('/core/patient/upload-profile-picture', formBody);
  if (!resJson.key) throw Error('Malformed response from backend');
  return resJson.key;
}

// Settings

export async function getFaxPhone() {
  const resJson = await postJson('/core/phone/get-fax-phone');
  return resJson.phoneNumber;
}

export async function setFaxPhone(phoneNumber) {
  const resJson = await postJson('/core/phone/set-fax-phone', { phoneNumber });
  return resJson.phoneNumber;
}

export async function getAccountSettings() {
  const resJson = await postJson('/core/account/get-settings');
  if (!resJson.settings) throw Error('Malformed response from backend');
  return resJson;
}

export async function setAccountSettings(payload) {
  const resJson = await postJson('/core/account/set-settings', payload);
  if (!resJson.settings) throw Error('Malformed response from backend');
  return resJson;
}

export async function getUserSettings(userId) {
  const resJson = await postJson('/core/user/get-settings', { userId });
  if (!resJson.settings) throw Error('Malformed response from backend');
  return resJson.settings;
}

export async function setUserSettings(userId, payload) {
  const resJson = await postJson('/core/user/set-settings', { userId, ...payload });
  if (!resJson.settings) throw Error('Malformed response from backend');
  return resJson.settings;
}