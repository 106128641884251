// router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import * as coreApi from '../api/core'

// Import route groups
import adminRoutes from './routes/admin'
import coreRoutes from './routes/core'
import messagingRoutes from './routes/messaging'
import formsRoutes from './routes/forms'
import faxingRoutes from './routes/faxing'
import patientRoutes from './routes/patient'

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/admin',
    redirect: '/admin/accounts'
  },
  ...adminRoutes,
  ...coreRoutes,
  ...messagingRoutes,
  ...formsRoutes,
  ...faxingRoutes,
  ...patientRoutes
]

const router = createRouter({
  routes,
  history: createWebHistory()
})

// Query parameter preservation
const PRESERVED_QUERY_PARAMS = ['appbar', 'sidebar', 'embed']

router.beforeEach((to, from) => {
  const preservedParams = {}
  PRESERVED_QUERY_PARAMS.forEach(param => {
    if (from.query[param] && !to.query[param]) {
      preservedParams[param] = from.query[param]
    }
  })

  if (Object.keys(preservedParams).length > 0) {
    return {
      ...to,
      query: {
        ...to.query,
        ...preservedParams
      }
    }
  }
})

router.beforeResolve(async (to) => {
  const otc = to.query.otc;
  if (otc) {
    try {
      await coreApi.useOneTimeCode(otc);
    } catch (err) {
      console.error('Failed to use one time code');
    }
  }
})

export default router;