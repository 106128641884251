export default [
  {
    name: 'Patient Chats',
    path: '/patient-chats/:id?',
    component: () => import(/* webpackChunkName: "messaging" */ '@/views/messaging/PatientChatsView.vue')
  },
  {
    name: 'Unverified Chats',
    path: '/unverified-chats/:id?',
    component: () => import(/* webpackChunkName: "messaging" */ '@/views/messaging/UnverifiedChatsView.vue')
  },
  {
    name: 'Group Chats',
    path: '/group-chats/:id?',
    component: () => import(/* webpackChunkName: "messaging" */ '@/views/messaging/GroupChatsView.vue')
  }
]
