import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { Capacitor, CapacitorHttp } from '@capacitor/core';
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import VTooltip from 'v-tooltip'
// import { VoIPPlugin } from './mobile/VoIPUtils.js'


const tooltipOptions = {
  distance: 12,
  themes: {
    tooltip: {
      delay: {
        show: 500,
        hide: 0
      }
    }
  }
}

const enableHttp = async () => {
  if (Capacitor.getPlatform() === 'ios') {
    await CapacitorHttp.configure({ // Use Cap HTTP which will switch native fetch for ios 
      ios: {
        enableNative: true
      }
    });
  }
};

enableHttp();


const app = createApp(App);
app.use(router);
app.use(VTooltip);
app.use(FloatingVue, tooltipOptions);
// app.use(VoIPPlugin)
app.mount('#app');
